
import { get, patch, post } from "@/api/axios";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ExaTaskState, IEmployee, IExaSku, IExaTask } from "../../state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import Security from "@/components/Security.vue";
import DragAndDrop from "@/components/DragAndDrop.vue";
import SingleUserSelect from "@/components/SingleUserSelect.vue";

@Component({
    components: {
        EntityPropertiesEdit,
        Security,
        DragAndDrop,
        SingleUserSelect
    }
})
export default class ExaSkuView extends Vue {
    @Prop({ required: false, default: "" })
    public skuID!: string;

    public tab: string = "tab-selected";

    public get skuIDParams(): string {
        return this.$route.params.id;
    }

    fields: Array<any> = [
        {
            "icon": "mdi-file-document",
            "caption": "Наименование комплекта",
            "placeholder": "Наименование не указано",
            "name": "name",
            "type": "string",
            "hint": "hint",
            "message": "Введите наименование комплекта"
        },
        {
            "icon": "mdi-file-document",
            "caption": "Тема",
            "placeholder": "Тема не указано",
            "name": "theme",
            "type": "string",
            "hint": "hint",
            "message": "Введите наименование темы"
        }
    ];

    taskDialog = {
        isActive: false,
        title: "",
    }



    assignDialog = {
        isActive: false,
        item: null as IExaTask | null,
        assignees: null as IEmployee | null
    }

    task_headers: Array<any> = [
        {
            text: 'Имя',
            align: 'start',
            sortable: true,
            value: 'name',
        },
        {
            text: 'Статус',
            align: 'start',
            sortable: true,
            value: 'status',
        },
        {
            text: 'Кол-во вопросов',
            value: 'qa_count',
        },
    ];

    getSID() {
        return this.skuIDParams ?? this.skuID;
    }

    getStatusColor(status: ExaTaskState) {
        if (status == "created")
            return "orange";
        if (status == "published")
            return "green";
        if (status == "archived")
            return "red";
        return "";
    }

    createTaskAndGoToEdit() {
        if (!this.taskDialog.title || this.taskDialog.title === "")
            return;
        post(`/api/@examination/exa/sku/${this.getSID()}/task/${this.taskDialog.title}`, {})
            .then(x => {
                console.log("Success create empty task", x.data);
                this.$router.push({ name: "exa_task_view", params: { id: x.data._id, sid: this.getSID() } });
            })
    }


    getLocaledText(text: string) {
        if (text == "published")
            return "Опубликовано";
        if (text == "created")
            return "Создано";
        return "Неизвестно"
    }

    public entity: IExaSku | null = null;
    public selected_tasks: IExaTask[] = [];
    public all_tasks: IExaTask[] = [];
    public task_search: any = null;
    public is_loaded: boolean = false;

    mounted() {

        get(`/api/@examination/exa/sku/${this.getSID()}`).then(async (x) => {
            this.entity = x.data;

            console.log("Success fetch entity",  this.entity);

            await get(`/api/@examination/exa/task/`).then((x) => {
                this.all_tasks = x.data;
                this.selected_tasks = this.all_tasks.filter(x => this.entity!.tasks.includes(x._id));
                this.is_loaded = true;
            });
        });
    }

    editTask(taskId: string) {
        this.$router.push({ name: "examinationTaskEdit", params: { id: taskId } });
    }

    completeAssign() {
        console.log(this.assignDialog);

        if (!this.assignDialog.assignees || !this.assignDialog.item) {
            return;
        }

        patch(`/api/@examination/exa/sku/${this.getSID()}/task/${this.assignDialog.item._id}/assign/${this.assignDialog.assignees._id}`, {}).
            then(x => {
                if (x.data)
                    console.log("Success assigned user to task");
                else
                    console.error("Failed assign user to task");
                this.assignDialog.isActive = false;
                this.assignDialog.item = null;
                this.assignDialog.assignees = null;
            });

    }

    onTaskMove(o: any) {
        console.log("onTaskMove", o);
    }

    onTaskRemove(o: any) {
        console.log("onTaskRemove", o);
    }

    onAddClick() {
        post(`/api/@examination/exa/sku/${this.entity!._id}/task`, { taskName: "empty task name" })
            .then(x => {
                this.$router.push({ name: "examinationTaskEdit", params: { id: x.data._id } });
            })
    }

    @Watch("selected_tasks")
    updateSku() {
        if (!this.is_loaded) return;
        let sku = this.entity;
        if (!sku) return;
        sku.tasks = this.selected_tasks.map(x => x._id);
        console.log("updateSku", sku);
        post(`/api/@examination/exa/sku/${sku._id}`, sku)
            .then(x => console.log("Success update sku", x.data));
    }
}
