<template>
  <v-menu v-if="!readonly">
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="ml-2"
            style="cursor: hand"
            color="primary"
            icon
            v-on="{ ...on, ...menu } "
          >
            <v-icon
              dark
              :color="item.security_owner === user._id ? 'red' : 'secondary'"
              >mdi-eye</v-icon
            >
          </v-btn>
        </template>
        <span>Владелец {{getOwnerName()}}</span>
      </v-tooltip>
    </template>
    <v-list>
      <!-- <v-list-item v-if="user.is_admin">
        <v-list-item-title>Администратор</v-list-item-title>
      </v-list-item> -->
      <v-list-item v-if="item.security_owner === user._id">
        <v-icon small color="primary">mdi-check</v-icon>
        <v-list-item-title>Владалец</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-else-if="!item.security_owner || user.is_admin"
        @click="takeOwnership"
      >
        <v-list-item-title>Стать владельцем</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-icon
          small
          color="primary"
          v-if="item.security_watchers && item.watchers.includes(user._id)"
          >mdi-check</v-icon
        >
        <v-list-item-title>Наблюдатель</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="user.is_admin" @click="removeOwnership">
        <v-list-item-title>Убрать владельца</v-list-item-title>
      </v-list-item>      
      <!-- <v-list-item
        v-for="(security, index) in securityEnumeration"
        :key="index"
        @click="
          item.security = security.name;
          updatesecurity();
        "
      >
        <v-list-item-title>
          <v-icon small color="primary" v-if="item.security === security.name">mdi-check</v-icon>
          {{ security.display }}
          </v-list-item-title>
      </v-list-item> -->
    </v-list>
  </v-menu>
  <!-- <v-chip class="ma-2" :color="securityColor" outlined v-else>
        {{ securityDisplay }}
      </v-chip> -->
</template>

<script>
import { stringify } from "query-string";

//const API_URL_ENTITY = process.env.VUE_APP_BASE_API_URL + "/contract";

export default {
  name: "security",
  props: {
    entity: null,
    readonly: { type: Boolean, default: false },
    item: null,
  },
  data: () => ({
    // securityEnumeration: [
    //   {
    //     name: "owner",
    //     display: "Владелец",
    //     color: "dark blue blue--text text--darken-1",
    //   },
    //   {
    //     name: "watcher",
    //     display: "Наблюдатель",
    //     color: "dark green green--text text--darken-1",
    //   },
    //   {
    //     name: "guest",
    //     display: "Гость",
    //     color: "dark grey grey--text text--darken-1",
    //   }
    // ],
    //user: null,
    parentId: null,
  }),
  computed: {
    // securityDisplay() {
    //   const d = this.securityEnumeration.find(
    //     (x) => x.name == this.item.security
    //   );
    //   return d ? d.display : "Не определен";
    // },
    // securityColor() {
    //   const d = this.securityEnumeration.find(
    //     (x) => x.name == this.item.security
    //   );
    //   return d ? d.color : "gray";
    // },
  },
  methods: {
    setOwnership(userId) {
      // todo make many checks... and on server side too
      this.item.security_owner = userId;

      fetch(`${process.env.VUE_APP_BASE_API_URL}/${this.entity}`, {
        method: "POST",
        body: JSON.stringify(this.item),
        headers: {
          "content-type": "application/json",
        },
      })
        .then((resp) => resp.json())
        .then((result) => {
          console.log("response is ", result);
          if (result.details == null) {
            // todo ??? no error????
            this.item = result;
          }
        });
    },
    takeOwnership() {
      this.setOwnership(this.user._id);
    },
    removeOwnership () {
      this.setOwnership('');
    },
    getOwnerName () {
      if (!this.item.security_owner || this.item.security_owner == '') return 'не задан';
      let owner = this.item.security_users.find(u=>u._id===this.item.security_owner);
      if (!owner) return 'не определен'
      return owner.login;
    }
  },
  //   ,
  //   updatesecurity() {
  //     // eslint-disable-next-line no-console
  //     console.log(`updatesecurity(${JSON.stringify(this.item)})`);
  //     // make post request
  //     fetch(`${process.env.VUE_APP_BASE_API_URL}/${this.entity}`, {
  //       method: "POST",
  //       body: JSON.stringify(this.item),
  //       headers: {
  //         "content-type": "application/json",
  //       },
  //     })
  //       .then((resp) => resp.json())
  //       .then((result) => {
  //         // eslint-disable-next-line no-console
  //         console.log("response is ");
  //         // eslint-disable-next-line no-console
  //         console.log(result);
  //         // add new message on a page
  //         if (result.details == null) {
  //           // update item view
  //           this.item.security = result.security;
  //         }
  //       });
  //   },
  // },
  //   created() {
  //     // console.log("created()");
  //     // if (this.obj != undefined) {
  //     //   this.item._id = this.obj._id;
  //     //   //this.item.security = this.obj.security;
  //     // }
  //   },
  mounted() {},
};
</script>
