
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
@Component({
    components: {
        draggable
    }
})
export default class MarketItems extends Vue {
    @Prop({ type: Array, default: () => [], required: true })
    public leftEntities!: any & { _id: string }[];
    @Prop({ type: Array, default: () => [], required: true })
    public rightEntities!: any & { _id: string }[];
    @Prop({ type: Function, default: () => { }, required: false })
    public onMove!: Function;
    @Prop({ type: Function, default: () => { }, required: false })
    public onRemove!: Function;
    @Prop({ type: Function, default: () => { }, required: false })
    public onAddClick!: Function;
    @Prop({ type: Function, default: (item: any) => 'DEFAULT TEXT FORMAT', required: false })
    public onTextFormat!: Function;

    @Prop({ type: String, required: true })
    public leftTitle!: string;
    @Prop({ type: String, required: true })
    public rightTitle!: string;

    public left_items: (any & { _id: string })[] = [];
    public right_items: (any & { _id: string })[] = [];
    @Prop({ type: String, required: false, default: "_default_" })
    public itemGroupName!: string;

    public isLoaded: boolean = false;

    getOptions() { 
        return { group: this.itemGroupName };
    }
    
    mounted() {
        this.$nextTick(() => {
            // FUCKING SHIT
            setTimeout(() => {
                this.left_items.push(...this.leftEntities);
                this.right_items.push(...this.rightEntities);
                console.log("Success mount DragAndDrop component", this.left_items, this.right_items);
                this.isLoaded = true;
            }, 500);
        });
    }

    @Watch("right_items")
    watch_right_items() {

    }
}
